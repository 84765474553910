@import 'abstracts/variables';
@import 'abstracts/helpers';

.mobile-navigation-wrapper {
  // Used for accurate vertical divider height to match the height of the top navigation
  @top-navigation-height: 76px;
  @top-navigation-vertical-padding: @spacer;

  .mobile-top-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: @spacer @spacer * 4;
    width: 100%;

    .logo-link-wrapper {
      display: flex;
      align-items: center; // Align logo vertically
    }

    .right-buttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .contact-us-button {
        margin-right: @spacer;
        font-family: @headings-font-family;
        font-weight: 700;
      }

      .mobile-country-picker {
        margin-right: @spacer;
      }
    }

    .vertical-divider {
      height: @top-navigation-height;
      margin-top: -@top-navigation-vertical-padding;
      margin-bottom: -@top-navigation-vertical-padding;
    }

    @media @below-md {
      padding: @spacer @spacer * 2;
    }

    @media @below-xs {
      flex-wrap: nowrap;

      .logo-link-wrapper {
        max-width: 80px;
      }

      .right-buttons-wrapper {
        .mobile-country-picker {
          max-width: 60px;
          margin-left: @spacer;
        }

        .customer-type-switcher {
          padding: 0;
        }
      }
    }
  }

  .mobile-navigation-bottom {
    padding: @spacer @spacer * 4;

    .mobile-navigation-bottom-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .right-items-wrapper {
        flex-grow: 1;
        max-width: 550px; // Cap width to match design
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: @spacer * 1.5;

        .nav-icon {
          padding-left: 0;
          padding-right: 0;

          svg {
            width: 26px;
            height: 26px;
          }

          &.catalog-icon {
            overflow: visible;
            position: relative;

            &:hover {
              path {
                fill: @primary-darker;
                stroke: @primary-darker;
              }
            }

            :global {
              .rs-badge-content {
                position: absolute;
                display: block;
                top: unset;
                left: unset;
                right: 4px;
                bottom: -13px;
                line-height: 1.3;
                padding: 1px 6px;
                color: @dim-darker;
                background-color: @primary-dark;
                font-weight: 600;
                border-radius: 50%;
              }
            }
          }

          &.heart-icon {
            top: 3px;
            overflow: visible;

            &:hover {
              path {
                stroke: @primary-darker;
              }
            }

            :global {
              .rs-badge-content {
                position: absolute;
                display: block;
                top: unset;
                bottom: -13px;
                right: 3px;
                line-height: 1.3;
                padding: 1px 6px;
                color: @dim-darker;
                background-color: @primary-dark;
                font-weight: 600;
                border-radius: 50%;
              }
            }
          }

          &.phone-icon {
            top: 4px;
          }

          &.search-icon {
            color: @dim-mid;
            margin-right: auto;
            margin-left: @spacer;
          }

          &.cart-icon {
            top: 3px;
            overflow: visible;

            svg {
              width: 30px;
              height: 30px;
            }

            :global {
              .rs-badge-content {
                position: absolute;
                display: block;
                top: unset;
                left: unset;
                right: 4px;
                bottom: -6px;
                line-height: 1.3;
                padding: 1px 6px;
                color: @dim-darker;
                background-color: @primary-dark;
                font-weight: 600;
                border-radius: 50%;
              }
            }
          }
        }
      }

      .internal-traffic {
        width: 34px;
      }
    }

    @media @below-md {
      padding: @spacer @spacer * 2;
    }
  }
}
